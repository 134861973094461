var links = $('.btn[href^="#"]');
var linkPure = document.querySelectorAll('.btn[href^="#"]');

var sections = $('.section[id]:not(#home)');

var limits = [];

function setLimitsMenu(){
  sections.each(function (index){
    limits[index] = $(this).offset().top;
  });
}



links.on('click', function(e) {
  e.preventDefault();
  var href = $(this).attr('href');
  var target = $(href);

  setLimitsMenu();
  $('html,body').animate({ scrollTop: target.offset().top},'slow');
  $('.patronos .card-large').unbind('mouseenter');
  setTimeout(function(){
    $('.patronos .card-large').on('mouseenter', function(){
        $('html,body').animate({ scrollTop: $('.patronos').offset().top},'slow');
    });
  }, 1000);

});

$(window).on('scroll', function() {
  var position = $(this).scrollTop() + 80;
  if (position - 80 == 0) {
    $('.menu').attr('data-background','false');
  }else {
    $('.menu').attr('data-background','true');
  }
  for (var i = 0; i < limits.length; i++) {
    if(linkPure[i] == linkPure[limits.length - 1] && position >= limits[i]){
      setItemAtivoMenu(linkPure[i]);
      break;
    } else if(linkPure[i] == linkPure[0] && position < limits[0]){
      $('.btn[data-ativo="true"]').attr('data-ativo','false');
      break;
    }else if(position >= limits[i] && position < limits[i + 1]){
      setItemAtivoMenu(linkPure[i]);
      break;
    }
  }
});

var teste = $(window).width();
if (teste > 1150) {

$('.patronos .card-large').on('mouseenter', function(){
    $('html,body').animate({ scrollTop: $('.patronos').offset().top},'slow');
});


$(window).on('resize load', function(){

    $('.patronos .card-large').jScrollPane();
    setLimitsMenu();
});
}
function setItemAtivoMenu(target) {
  $('.btn[data-ativo="true"]').attr('data-ativo','false');
  target.setAttribute('data-ativo','true');

}
function goHome() {
  $('html,body').animate({ scrollTop: $('.home').offset().top},'slow');
}
function goCausa() {
  $('html,body').animate({ scrollTop: $('.causa').offset().top},'slow');
}
